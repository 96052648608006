export const projectsEn = [
    {
      id:"fodbuza",
      name: "Federal Public Service Foreign Affairs",
      technologies: 'Microsoft Identity Manager, Active Directory, Visual Studio, PowerShell',
      country: 'Belgium',
      images: [
          {
              title:'Ministry of Foreign Affairs Belgium',
              src: "assets/images/buza.png",
          },
      ],
      url: "https://diplomatie.belgium.be/en",
    detail:"<p><strong>Role:</strong> Security Consultant</p><p>The Federal Public Service Foreign Affairs, Foreign Trade and Development Cooperation (FPS Foreign Affairs) is the foreign affairs ministry of Belgium and is responsible for Belgian foreign policy, relations with the European Union, development cooperation policy and certain aspects of foreign trade policy. <br /><br />The Foreign Affairs network encompasses some 130 embassies, consulates and representations in foreign countries as well as in Belgium. These bodies are run from the central government in Brussels.</p>",
      detailIsDisplayed: false,
      responsabilities: [
        {
          desc:'Operational Support for the Microsoft Identity Management setup'
        },
        {
          desc: 'Implementation of change requests regarding existing use-cases'
        },
        {
          desc: 'Implement new requirements regarding data flow from HR to AD'
        },
        {
          desc: 'Delivery of Identity Management documentation and procedures'
        },
      ],
    },
    {
      id:"biza",
      name: "Federal Public Service Home Affairs",
      technologies: 'Microsoft Identity Manager, Active Directory, Visual Studio, PowerShell, C#',
      country: 'Belgium',
      images: [
          {
          title:'Federal Public Service Home Affairs',
              src:   "assets/images/biza.jpg",
          },            
      ],
      url:'https://www.ibz.be/nl',
      detail:"<p><strong>Role:</strong> Security Consultant</p><p>The aim of the Federal Public Service Home Affairs is to prepare and implement the policies of the Federal Home Affairs Minister in the following areas: police and civil security, crisis management, policy regarding non-Belgian nationals, the registration and identification of natural persons and the management of institutional and regulatory issues, as well as the exercise of democratic rights.</p>",
      detailIsDisplayed: false,
      responsabilities: [
        {
          desc: 'Operational Support for the Microsoft Identity Management setup'
        },
        {
          desc: 'Implement MIM SSPR and provide guidance and documentation for deploying the MIM SSPR client.'
        },
        {
          desc: 'Correlate Human Resources-data with Active Directory and provide guidelines for clean up of orphaned accounts in Active Directory'
        },
        {
          desc: 'General Active Directory administration'
        },
        {
          desc: 'Additional security services under non-disclosure agreement'
        },
      ],
    },
    {
      id:"justice",
      name: "Federal Public Service Justice",
      technologies: 'Forefront & Microsoft Identity Manager, Visual Studio, PowerShell, C# .NET',
      images: [
          {
          title:'Federal Public Service Justice',
              src:   "assets/images/justice.png",
          },
      ],
      url:'https://justitie.belgium.be/en',
      detail:"<p><strong>Role:</strong> Security Consultant</p><p>The aim of Federal Public Service Justice is a combined objective to provide legal advice, support the judicial apparatus and carry out sentences is to contribute towards a more harmonious relationship between the person in the street and the justice system.</p>",
      country: 'Belgium',
      detailIsDisplayed: false,
      responsabilities: [
        {
          desc: 'Technical design for new identity management solution & migration'
        },
        {
          desc: 'Technical implementation of new identity management solution'
        },
        {
          desc: 'Provide documentation & procedures on the new identity management solution'
        },
      ],
  },
  {
    id: "pm",
    name: "Federal Public Service Chancellery of the Prime Minister",
    technologies: 'Forefront Identity Manager, Visual Studio, PowerShell, C# .NET',
    images: [
      {
        title: 'Federal Public Service Chancellery of the Prime Minister',
        src: "assets/images/kanselarij_van_de_eerste_minister_logo.jpg",
      },
    ],
    url: 'https://justitie.belgium.be/nl',
    detail: "<p><strong>Role:</strong> Security Consultant</p><p>The Chancellery of the Prime Minister FPS supports the Prime Minister in guiding and coordinating government policy. This FPS provides the Prime Minister with support in areas such as content and administration, as well as aspects of legal, logistical and communication-related matters. This FPS also acts as a point of contact with various federated entities on the one hand and the European Union on the other.</p>",
    country: 'Belgium',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Installation and configuration Forefront Identity Manager'
      },
      {
        desc: 'Integration Forefront Identity Manager with multiple Active Directory forests'
      },
      {
        desc: 'Integration of PowerShell workflows for management Exchange rooms & equipment'
      },
      {
        desc: 'Installation and configuration Self-Service Password Reset-portal'
      },
      {
        desc: 'Presentation Proof-of-Concept'
      },
      {
        desc: 'Documentation and hand-over of the solution'
      }
    ],
  },
  {
    id: "nb",
    name: "National Bank of Belgium",
    technologies: 'Hitachi ID PAM, Visual Studio, Postman, C# .NET',
    images: [
      {
        title: 'National Bank of Belgium',
        src: "assets/images/national_bank_of_belgium_logo.jpg",
      },
    ],
    url: 'https://nbb.be/en',
    detail: "<p><strong>Role:</strong> Security Consultant</p><p>The National Bank of Belgium has been the central bank of Belgium since its foundation in 1850. The Bank is charged with tasks of general interest, with a central focus on monetary stability. The bodies of the Bank are the Governor, the Executive Committee, the Council of Regency, the Sanctions Committee and the Resolution College.</p>",
    country: 'Belgium',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Installation Hitachi ID PAM backend'
      },
      {
        desc: 'Development custom Hitachi ID API'
      },
    ],
  },
  {
    id: "enabel",
    name: "Enabel - Belgian Development Agency",
    technologies: 'Microsoft Entra, Azure Function App, Visual Studio, C#',
    images: [
      {
        title: 'Enabel',
        src: "assets/images/enabel.jpg",
      },
    ],
    url: 'https://www.enabel.be/',
    detail: "<p><strong>Role:</strong> IAM Architect</p><p>Enabel is the development cooperation agency of the Belgian federal government. Until 2017, Enabel was known as the Belgian Technical Cooperative (BTC).<br/><br/> Together with its partners at home and abroad, Enabel provides solutions to address pressing global challenges - climate change, urbanization, human mobility, peace and security, economic and social inequality - and to promote global citizenship. In 2024, Enabel will employ 2,000 employees. The agency manages 150 projects in about twenty countries, in Belgium, Africa and the Middle East.</p>",
    country: 'Belgium',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Validate & enhance security of Enabel\'s Microsoft Entra setup'
      },
      {
        desc: 'Design & implement Identity Governance processes'
      },
    ],
  },
  {
    id:"pwc",
    name: "PwC",
    technologies: 'Microsoft Identity Manager, Visual Studio, PowerShell, C# .NET, Lotus Notes, SAP, Citrix',
    images: [
        {
        title:'PwC',
            src:   "assets/images/pwc_logo.jpg",
        },

    ],
    url:'https://www.pwc.be/',
    detail:"<p><strong>Role:</strong> IAM Architect</p><p>PwC provides professional services across Audit and Assurance, Advisory and Tax. We bring a range of capabilities to help organizations solve faster, solve more and realize more value. These capabilities include cloud and digital, deals, ESG, cybersecurity and privacy, governance/boards, risk, transformation, and much more. Across our global network of nearly 328,000 professionals in 152 countries.</p>",
    country: 'Multinational',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Technical design for integration of Microsoft Identity Manager'
      },
      {
        desc: 'Development & integration custom SAP connector'
      },
      {
        desc: 'Development & integration custom Citrix connector'
      },
      {
        desc: 'Installation and configuration Self-Service Password Reset portal for multiple Active Directory forests.'
      },      
      {
        desc: 'Technical design for integration of Microsoft Identity Manager'
      },
      {
        desc: 'Documentation and hand-over of the solution'
      }
    ],
  },
  {
    id: "sanoma",
    name: "Sanoma",
    technologies: 'Microsoft Entra, Figma, Snowflake, Monday.com,',
    images: [
      {
        title: 'Sanoma',
        src: "assets/images/sanoma.png",
      },

    ],
    url: 'https://www.sanoma.com/en/',
    detail: "<p><strong>Role:</strong> IAM Architect</p><p>Sanoma consists of two strong and focused businesses, one of the leading K12 education businesses globally and a market-leading cross-media business in Finland.<br /><br />Sanoma operates in twelve European countries and employ more than 5,000 professionals.In 2023, our net sales amounted to approx. 1.4bn€ and our operational EBIT margin excl.PPA was 12.6%.</p>",
    country: 'Multinational',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Technical advisory on SaaS application onboarding'
      },
      {
        desc: 'Technical implementation on SaaS application onboarding'
      },
    ],
  },
  {
    id: "picanol",
    name: "Picanol",
    technologies: 'Microsoft Identity Manager, Visual Studio, PowerShell, C# .NET',
    images: [
      {
        title: 'Picanol',
        src: "assets/images/picanol_logo.jpg",
      },

    ],
    url: 'https://www.picanol.be/',
    detail: "<p><strong>Role:</strong> IAM Architect</p><p>Picanol Group (part of Tessenderlo Group, Euronext: TESB) is composed of four branded entities: Picanol (weaving machines), Proferro (foundry and mechanical finishing), Psicontrol (development and production of electronics) and Melotte (3D metal printing & high precision manufacturing).<br /><br />Founded and headquartered in 1936 in Belgium (Europe). Almost 2,300 employees with more than 35 nationalities worldwide, 14 sites worldwide.</p>",
    country: 'Multinational',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Analysis scope and requirements for new identity management solution'
      },
      {
        desc: 'Document business processes and use cases'
      },
      {
        desc: 'Technical design for the Microsoft Identity Management components with regard to desired functionalities'
      },
      {
        desc: 'Organizing workshops for validation design'
      },
      {
        desc: 'Implementation new identity management solution'
      },
      {
        desc: 'Training of the internal actors for the use of the new identity management solution'
      },
      {
        desc: 'Assistance with design & implementation of change requests'
      },
      {
        desc: 'Operational support'
      },
    ],
  },
  {
    id: "lbc",
    name: "LBC Tank Terminals",
    technologies: 'Microsoft Entra, Azure Function App, Azure Logic App, Azure DevOps, Dynamics365, Visual Studio, PowerShell, C# .NET',
    images: [
      {
        title: 'LBC Tank Terminals',
        src: "assets/images/lbc.jpg",
      },

    ],
    url: 'https://www.lbctt.com/',
    detail: "<p><strong>Role:</strong> IAM Architect</p><p>From storage and handling of bulk liquid products to IBC filling, drumming, and warehousing, LBC is your connected partner for sustainable storage solutions. <br /><br />LBC TT operates 7 global terminals, supported by 500 dedicated employees, and offers a storage capacity of 2.9 million m³</p>",
    country: 'Multinational',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Technical design for the new identity & access management solution in Microsoft Entra'
      },
      {
        desc: 'Integration of the new identity & access management solution in Microsoft Entra'
      },
      {
        desc: 'Integration of Dynamics 365 HR data into the new identity & access management solution'
      },
      {
        desc: 'Documentation & training on the identity management solution'
      },
    ],
  },
  {
    id: "odisee",
    name: "Odisee",
    technologies: 'Microsoft Identity Manager, Active Directory, Microsoft Exchange, Visual Studio, PowerShell, C# .NET',
    images: [
      {
        title: 'Odisee',
        src: "assets/images/odisee.jpg",
      },

    ],
    url: 'https://www.odisee.be/',
    detail: "<p><strong>Role:</strong> IAM Architect</p><p>Odisee is a university of applied sciences with 6 campuses: in Aalst, Brussels, Dilbeek, Schaarbeek, Ghent and Sint-Niklaas. Each campus is dynamic centre of education, research and social services, located at the heart of Belgium. Odisee is one of the largest universities of applied sciences in Flanders and offers team-based education with a personal approach.<br/> <br/> Odisee is a member of the KU Leuven Association, a network of five universities of applied sciences and one university backed by an impressive system of national partners with more than 80.000 students in 23 cities and municipalities.</p>",
    country: 'Belgium',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Analysis scope and requirements for new identity management solution'
      },
      {
        desc: 'Technical design for the new identity & access management solution'
      },
      {
        desc: 'Development custom teams-management portal'
      },
      {
        desc: 'Implementation new identity management solution'
      },
      {
        desc: 'Training of the internal actors for the use of the new identity management solution'
      },
      {
        desc: 'Assistance with design & implementation of change requests'
      },
      {
        desc: 'Documentation & guidance on identity management solution'
      },
    ],
  },
  {
    id: "go",
    name: "GO! - Education of the Flemish Community",
    technologies: 'Forefront Identity Manager, Active Directory, Microsoft Exchange, C# .NET',
    images: [
      {
        title: 'GO!',
        src: "assets/images/go.jpg",
      },

    ],
    url: 'https://g-o.be/',
    detail: "<p><strong>Role:</strong> IAM Architect</p><p>GO! organises official education in the Dutch speaking part of Belgium. It is an autonomous body of the Flemish government which functions independently of the Flemish Minister of Education. GO! provides education from nursery school, through compulsory school age through to adult education and including schools specialising in creative and performing arts and technical and professional education. <br /> <br />GO! provides policy guidance, curriculum development and teacher training for its 700 schools / centres and for 35, 500 members of staff serving 220,000 students in compulsory education and 109,000 in adult education.</p>",
    country: 'Belgium',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Analysis and determining scope and requirements'
      },
      {
        desc: 'Documenting business processes and use cases.'
      },
      {
        desc: 'Technical design for the FIM components with relation to desired functionalities'
      },
      {
        desc: 'Organizing workshops for inventory needs and validation design'
      },
      {
        desc: 'Supervising FIM engineer'
      },
    ],
  },
  {
    id: "acerta",
    name: "Acerta",
    technologies: 'Microsoft Identity Manager, Active Directory, Visual Studio, PowerShell, C# .NET',
    images: [
      {
        title: 'Acerta',
        src: "assets/images/acerta.jpg",
      },

    ],
    url: 'https://www.acerta.be/',
    detail: "<p><strong>Role:</strong> IAM Engineer</p><p>Acerta is an HR services group in Belgium, specialized in advising, computerizing and managing administrative processes such as wage, social security, family allowances and residence formalities. <br ><br />Acerta works with more than 1.300 employees spread over 25 offices in Flanders, Brussels and Wallonia. The Group had a turnover of more than 154 million euros in 2011.</p>",
    country: 'Belgium',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Installation and configuration of Forefront Identity Manager for the management of Active Directory security groups which are used by SSCM for software distribution'
      },
      {
        desc: 'Configuration of workflow approval processes in FIM, where users can request software subject to approval from management, with the aim to control the software license-cost'
      },
      {
        desc: 'Installation and configuration of Self-Service Password Reset-portal to improve the user experience and productivity'
      },
      {
        desc: 'Configuration Forefront Identity Manager for reporting about the data-quality of the different identity stores.'
      },
      {
        desc: 'Configuration Forefront Identity Manager for provisioning of newly acquired employees'
      },
      {
        desc: 'Documentation & training on identity management solution'
      },
    ],
  },
  {
    id: "sec",
    name: "Security Services",
    technologies: 'Microsoft Windows, BackTrack/Kali Linux, OWASP\'s ZAP, Tenable Nessus',
    images: [
      {
        title: 'Security Services - Under NDA',
        src: "assets/images/unknown.jpg",
      },

    ],
    url: '',
    detail: "<p><strong>Role:</strong> Security Consultant</p><p>Enterprise security services under strict non-disclosure agreements.</p>",
    country: 'N/A',
    detailIsDisplayed: false,
    responsabilities: [
      {
        desc: 'Web application vulnerability assessments'
      },
      {
        desc: 'Grey & black box penetration testing'
      },
      {
        desc: 'Security assessments'
      }
    ],
  },
];

