<section id="certification">
    <div class="container">
        <h2><strong>   {{'education.title' | translate}}</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <h4><strong>{{'education.eductionTitle' | translate}}</strong></h4>
                <ul class="timeline">
                    <li>
                      <h5><img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31" class="vendor" /></h5>
                      <em class="font-italic"><strong>Microsoft Certified Expert</strong><br />Cybersecurity Architect </em>
                      <p class="year p-2 d-flex justify-content-center">2024 - 2026</p>
                    </li>
                    <li>
                        <h5><img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31" class="vendor" /></h5>
                        <em class="font-italic"><strong>Microsoft Certified Associate</strong><br />Identity and Access Administrator </em>
                        <p class="year p-2 d-flex justify-content-center">2023 - 2025</p>
                    </li>
                    <li>
                        <h5><img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31" class="vendor" /></h5>
                        <em class="font-italic"><strong>Microsoft Certified Associate</strong><br />Azure Security Engineer </em>
                        <p class="year p-2 d-flex justify-content-center">2021 - 2024</p>
                    </li>
                    <li>
                        <h5><img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31" class="vendor" /></h5>
                        <em class="font-italic"><strong>Microsoft Certified Solutions Expert </strong><br />Cloud Platform and Infrastructure</em>
                        <p class="year p-2 d-flex justify-content-center">2016</p>
                    </li>
                    <li>
                        <h5><img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31" class="vendor" /></h5>
                        <em class="font-italic"><strong>Microsoft Certified Solutions Expert </strong><br />Server Infrastructure</em>
                        <p class="year p-2 d-flex justify-content-center">2015</p>
                    </li>
                    <li>
                        <h5><img src="https://images.credly.com/images/4de1c3b8-f9f6-4447-8879-e4f74012f8b8/blob.png" class="vendor ec" /></h5>
                        <em class="font-italic"><strong>EC-Council </strong><br />Certified Incident Handler (ECIH)</em>
                        <p class="year p-2 d-flex justify-content-center">2015</p>
                    </li>
                    <li>
                        <h5><img src="https://getlogovector.com/wp-content/uploads/2020/10/tenable-inc-logo-vector.png" class="vendor tenable" /></h5>
                        <em class="font-italic"><strong>Tenable  </strong><br />Certified Nessus Auditor</em>
                        <p class="year p-2 d-flex justify-content-center">2015</p>
                    </li>
                    <!-- <li>
                        <h5><img src="https://getlogovector.com/wp-content/uploads/2020/10/tenable-inc-logo-vector.png" class="vendor tenable" /></h5>
                        <em class="font-italic"><strong>Tenable  </strong><br />Nessus 6 Advanced Capabilities</em>
                        <p class="year p-2 d-flex justify-content-center">2015</p>
                    </li>
                    <li>
                        <h5><img src="https://getlogovector.com/wp-content/uploads/2020/10/tenable-inc-logo-vector.png" class="vendor tenable" /></h5>
                        <em class="font-italic"><strong>Tenable  </strong><br />Nessus 6 ompliance Auditing</em>
                        <p class="year p-2 d-flex justify-content-center">2015</p>
                    </li> -->
                    <li>
                        <h5><img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31" class="vendor" /></h5>
                        <em class="font-italic"><strong>Microsoft Certified Solutions Associate </strong><br />Windows Server 2012</em>
                        <p class="year p-2 d-flex justify-content-center">2014</p>
                    </li>
                    <li>
                        <h5><img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31" class="vendor" /></h5>
                        <em class="font-italic"><strong>Microsoft Certified Technology Specialist </strong><br />Forefront Identity Manager 2010, Configuration</em>
                        <p class="year p-2 d-flex justify-content-center">2013</p>
                    </li>
                    <li>
                        <h5><img src="https://itcurated.com/infosecindex/wp-content/uploads/sites/35/2017/01/vasco-cybersecurity.jpg" class="vendor vasco" /></h5>
                        <em class="font-italic"><strong>VASCO </strong><br />DIGIPASS Ambassador</em>
                        <p class="year p-2 d-flex justify-content-center">2013</p>
                    </li>
                    <!-- <li>
                        <h5><img src="https://itcurated.com/infosecindex/wp-content/uploads/sites/35/2017/01/vasco-cybersecurity.jpg" class="vendor vasco" /></h5>
                        <em class="font-italic"><strong>VASCO </strong><br />Certified Engineer for IDENTIKEY Federation Server 1.4</em>
                        <p class="year p-2 d-flex justify-content-center">2013</p>
                    </li>
                    <li>
                        <h5><img src="https://itcurated.com/infosecindex/wp-content/uploads/sites/35/2017/01/vasco-cybersecurity.jpg" class="vendor vasco" /></h5>
                        <em class="font-italic"><strong>VASCO </strong><br />Certified Engineer for IDENTIKEY Authentication Server 3.3</em>
                        <p class="year p-2 d-flex justify-content-center">2013</p>
                    </li> -->
                    <li>
                        <h5><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwufWmDxoZXq7tP6KNia7guGdX8pTzUUPq4A&s" class="vendor" /></h5>
                        <em class="font-italic"><strong>Imprivata </strong><br />Certified Engineer</em>
                        <p class="year p-2 d-flex justify-content-center">2013</p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <h4><strong>{{'education.experiencesTitle' | translate}}</strong></h4>
                <ul class="timeline">
                    <li *ngFor="let experience of experiences;">
                        <h5>{{experience.title}}</h5>
                        <em>{{experience.company}}</em>
                        <div class="d-flex flex-row">
                            <p class="year p-2 d-flex justify-content-center">{{experience.period}}</p>
                            <button (click)="detailOnClick(experience)" type="button"
                                class="btn btn-primary">
                                <span *ngIf="!experience.detailIsDisplayed">+</span><span *ngIf="experience.detailIsDisplayed">-</span></button>
                        </div>
                        <p *ngIf="experience.detailIsDisplayed">
                            <span *ngFor="let mission of experience.missions;let index = index">
                                <strong>{{'education.mission' | translate}} {{index+1}}: </strong>{{mission}} <br>
                            </span>
                            <strong>{{'education.technologies' | translate}}:</strong> {{experience.technologies}}
                        </p>
                    </li>

                </ul>
            </div>
            <div class="col-lg-4 col-md-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                <div class="container row">
                  <div class="aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">

                    <h4 class="languages"><strong>{{'education.languages' | translate}}</strong></h4>
                    <div class="noProgress langue">
                      <span class="">
                        <img src="assets/images/dutch.png" width="20"
                             alt="drapeau de la france"><strong> {{'education.dutch' | translate}}</strong> <i class="val">
                          {{'education.mothertongue' | translate}}
                        </i>
                      </span>
                    </div>
                    <div class="noProgress langue">
                      <span class="">
                        <img src="assets/images/united-kingdom-flag.png" width="20"
                             alt="drapeau du royaume uni"><strong> {{'education.english' | translate}}</strong> <i class="val"> {{'education.fluent' | translate}}</i>
                      </span>
                    </div>
                    <div class="noProgress langue">
                      <span class="">
                        <img src="assets/images/france-flag.png" width="20"
                             alt="drapeau de la france"><strong> {{'education.french' | translate}}</strong> <i class="val">
                          {{'education.intermediate' | translate}}
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="container row">
                    <div class="mt-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                        <h4><strong>{{'education.centerOfInterest' | translate}}</strong></h4>
                        <ul class="timeline">
                            <li>
                                <h5>{{'education.bachlorDegree' | translate}}</h5>
                               <em class="font-italic"><strong>{{'education.bachlorDegreeDesc' | translate}}</strong>
                                <br/> {{'education.university' | translate}}</em>
                                <p class="year p-2 d-flex justify-content-center">2009 - 2013</p>
                            </li>
                        </ul>
                        <!-- <div class="noProgress langue">
                            <span>
                                <fa-icon [icon]="volleyBallIcon"></fa-icon><strong> {{'education.sport' | translate}}</strong> <i class="val"> 5 {{'education.years' | translate}}
                                    Volley Ball</i>
                            </span>
                        </div>

                        <div class="noProgress langue">
                            <span>
                                <fa-icon [icon]="planeIcon"></fa-icon><strong> {{'education.trips' | translate}}</strong> <i class="val">
                                    {{'education.tripsCountries' | translate}} </i>
                            </span>
                        </div>
                        <div class="noProgress langue">
                            <span>
                                <fa-icon [icon]="gameIcon"></fa-icon><strong> {{'education.boardGames' | translate}} </strong> <i class="val">
                                    {{'education.boardGamesPlayed' | translate}} </i>
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
