import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from '../service/translation-loader.service';
import { TranslateModule } from '@ngx-translate/core';
import { locale as english } from '../shared/i18n/en';
import { locale as french } from '../shared/i18n/fr';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  skills:any={
    technologies:[{name:".NET C#",percent:90,remark:'excellent'},{name:"Windows PowerShell",percent:100,remark:'expert'},{name:"Node.js",percent:80,remark:'very-good'}, {name:"JavaScript, BootStrap, Angular", percent:80,remark:'very-good'}, {name:"HTML, CSS", percent:100,remark:'expert'}, {name:"Java", percent:50,remark:'average'}],
    tools: [{ name: "Azure DevOps", percent: 80, remark: 'excellent' }, { name: "Visual Studio", percent: 90, remark: 'excellent' }, { name: "Git", percent: 70, remark: 'good' }, { name: "Notepad++", percent: 90, remark: 'excellent' }, { name: "Postman", percent: 90, remark: 'excellent' }, { name: "Microsoft O365", percent: 90, remark: 'excellent' }],
    methodologies: [{ name: "Microsoft Identity Manager", percent: 100, remark: 'expert' }, { name: "Microsoft Azure", percent: 90, remark: 'excellent' }, { name: "Microsoft Entra", percent: 100, remark: 'expert' }, { name: "SecDevOps CI/CD", percent: 70, remark: 'good' },{name:"Windows Server",percent:100,remark:'expert'},{name:"Active Directory",percent:90,remark:'excellent'}]
  };

  constructor(private _translationLoaderService: TranslationLoaderService) {
    this._translationLoaderService.loadTranslations(english, french);
  }

  ngOnInit(): void {
  }

}

    
    
  
