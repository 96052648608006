export const locale = {
    lang: 'en',
    data: {
        'about': {
            'title': 'About',
            'jobTitle': 'Security / Identity & Access Management Architect',
            'introductionOfMe': 'Wouter Landuyt is a seasoned cybersecurity professional based in Antwerp, Belgium, currently serving as a Member of ACEN, a leading provider of comprehensive cybersecurity solutions. With a strong foundation in security architecture and consulting, he has amassed extensive experience over more than a decade in various roles, including IAM Architect at the Ministry of Foreign Affairs Belgium and a Security Consultant for prominent organizations such as PwC and National Bank of Belgium.',
            'passionateAboutDevelopment':'Passionate about development',
            'mobility': 'Mobile throughout Flanders',
            'years': 'Years',
            'graduation': "Microsoft Certified Professional",
            'myResearch': 'CDI contract search',
            'researchDescription': 'His expertise lies in identity & access management solutions and tailored security services, ensuring that businesses are equipped to face evolving cyber threats. Wouter is dedicated to delivering personalized customer support, emphasizing responsiveness and approachability in his work.',
            'and':'and',
            'followMeOn':'Follow me on',
        },
        'contact': {
            'contactMe': 'Contact Me',
            'notHesitateToContactMe': 'Do not hesitate to contact me',
            'identify': 'Identify',
            'enterIdentify': 'Enter your identify',
            'email': 'Email',
            'enterEmail': 'Enter your email',
            'object': 'Services',
            'enterObject': 'Choose service',
            'message': 'Message',
            'send': 'Send',
        },
        'education': {
            'title': 'Certifications & Experience',
            'eductionTitle': 'Certifications',
            'experiencesTitle': 'Experiences',
            'centerOfInterest': 'Education',
            'sport': 'Sport',
            'trips': 'Trips',
            'boardGames': 'Board games',
            'languages': 'Languages',
            'fluent': 'Fluent',
            'goodLevel': 'Excellent',
            'intermediate': 'Intermediate',
            'mothertongue': 'Native Language',
            'french': 'French',
            'english': 'English',
            'dutch': 'Dutch',
            'masterDegreeTwo': "Master's degree 2 IT",
            'masterDegreeTwoDesc': "Master of degital services",
            'masterDegreeOne': "Master's degree 1 IT",
            'masterDegreeOneDesc': "General Master Computer Science",
            'bachlorDegree': "Bachelor's degree",
            'bachlorDegreeDesc': "Bachelor of Applied Computer Science",
            'university': 'Karel de Grote Hogeschool, Belgium',
            'mission':'Responsability',
            'technologies':'Technologies',
            'detail':'Detail',
            'tripsCountries':'Belgium, Spain, Netherlands, Swiss',
            'boardGamesPlayed':'Checkers and dominoes game, Among US, Ludo, ..etc',
            'years':'years'
        },
        'header': {
            'home': 'Home',
            'about': 'About',
            'skills': 'Skills',
            'references': 'References',
            'contactMe': 'Contact me',
        },
        'home': {
            'iAmDeveloper': 'I\'m an ',
        },
        'projects': {
            'title': 'Projects',
            'projectDetail':'Project detail',
            'sourceCode':'Homepage',
            'country': 'Country',
            'responsabilities': 'Responsabilities',
        },
        'references': {
            'title': 'References',
        },
        'skills': {
            'title': 'Skills',
            'technologies': 'Programming & Coding',
            'tools': 'Tools',
            'methodologies': 'Technologies'
        },
    }
}
