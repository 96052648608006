export const referencesEn = [
    {
        personImg: "assets/images/logo-odisee.jpg",
        personFullname: 'Wim Herremans',
        personPosition: "Lead IT Engineer",
        personCompany: 'Odisee',
        message: "Wouter did good job",
    },
];

