export const experiencesEn = [
    //{
    //    title: "Freelance IT Consultant",
    //    company: 'Landuyt BV, Belgium',
    //    period: "2025 - present",
    //    technologies: 'Microsoft Identity Manager, Microsoft Azure, Microsoft Entra, Active Directory, PowerShell, C# .NET',
    //    missions: [
    //        "Plan, design & implement cloud-based Identity & Access Management solutions",
    //        "Advisory for IT solutions",
    //    ],
    //    detailIsDisplayed: false,
    //},

    {
      title: "IAM Architect",
      company: 'IS4U NV, Belgium',
      period: "2024 - 2025",
      technologies: 'Microsoft Identity Manager, Microsoft Azure, Microsoft Entra, Active Directory, PowerShell, C# .NET',
      missions: [
        "Technical Lead - Microsoft Entra",
        "Plan, design & implement Identity & Access Management solutions",
        "Training on Identity & Access Management solutions",
      ],
      detailIsDisplayed: false,
    },
    {
        title: "IAM Architect",
        company: 'Orlox NV, Belgium',
        period: "2021 - 2024",
        technologies: 'Microsoft Identity Manager, Microsoft Azure, Microsoft Entra, Active Directory, PowerShell, C# .NET',
        missions: [
            "Technical Lead - Microsoft Entra",
            "Plan, design & implement Identity & Access Management solutions",
            "Training on Identity & Access Management solutions",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Security Consultant",
        company: 'IS4U NV, Belgium',
        period: "2012 - 2021",
        technologies: 'Forefront Identity Manager & Microsoft Identity Manager, Active Directory, Exchange, C# .NET, Javascript, Tenable Nessus, Kali Linux',
        missions: [
            "Plan, design & implement Identity & Access Management solutions",
            "Training on Identity & Access Management solutions",
            "Vulnerability Assessments & Penetration tests",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Product Analyst Intern",
        company: 'IS4U NV, Belgium',
        period: "2012",
        technologies: 'QualysGuard, Tenable Nessus, Rapid7, Kali Linux, Metasploit',
        missions: [
            "Product analyst for ethical hacking software & PoC penetration tester.",
        ],
        detailIsDisplayed: false,
    },
];

