<section id="projects">
    <div class="container">
        <h2><strong>{{'projects.title' | translate}} </strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-sm-12 " data-aos="flip-up" *ngFor="let project of projects;">
                <div class="card shadow-lg p-3 mb-5 bg-white rounded">
                    <div class="card-header d-flex flex-column">
                        <div id="{{project.id}}-img" class="img-wrap" style="min-height: 300px; width:100%;  "
                            [ngStyle]="{'backgroundImage':'url('+project.images[0].src+')'}">
                        </div>
                        <!-- <div id="{{project.id}}-link" class="links mt-auto d-flex justify-content-center">
                            <a *ngFor="let image of project.images;let index=index" href="{{image.src}}" data-gall="project.id"
                                class="venobox vbox-item" title="{{image.title}}"><i *ngIf="index==0" class="bx bx-zoom-in bx-md"></i></a>
                            <a href="{{project.url}}"
                                title="" target="_BLANK"><i class="bx bx-link bx-md"></i></a>
                        </div> -->
                    </div>
                    <div class="card-body">
                      <h3 class="card-title">{{project.name}}</h3>
                      <h5 class="card-title"><strong>Country:</strong> {{project.country}}</h5>

                      <h5 class="card-title"><strong>Technologies:</strong> </h5>
                      <p class="card-text">{{project.technologies}}</p>
                      <div class="d-flex flex-row justify-content-center">
                        <a href="{{project.url}}" target="_blank" target="_blank"
                           type="button" class="btn mr-3">{{'projects.sourceCode' | translate}}</a>
                        <button (click)="detailOnClick(project)" type="button" class="btn">
                          <span *ngIf="!project.detailIsDisplayed">+</span><span *ngIf="project.detailIsDisplayed">-</span>
                          {{'projects.projectDetail' | translate}}



                        </button>
                      </div>

                      <div *ngIf="project.detailIsDisplayed" [innerHTML]="project.detail" class="mt-3 detailcon">
                      </div>

                      <div *ngIf="project.detailIsDisplayed">
                        <br />
                        <strong>{{'projects.responsabilities' | translate}}:</strong>
                        <br />
                        <br />
                        <ul *ngFor="let responsability of project.responsabilities;">
                          <li class="">
                            <span class=''>{{responsability.desc}}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
