<section id="about">
    <div class="container">
        <h2><strong>{{'about.title' | translate}}</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-md-12" data-aos="fade-right">
                <picture>
                    <img src="assets/images/profile.jpg" alt="photo de profil" class="img-fluid">
                </picture>
                <div class="d-flex justify-content-center">
                    
                </div>
            </div>
            <div class="col-lg-8  col-md-12 pt-4 pt-lg-0" data-aos="fade-left">
                <h3>{{'about.jobTitle' | translate}}</h3>
                <br />
                <p class="font-italic">
                    {{'about.introductionOfMe' | translate}}
                </p>
                <br />
                <div class="row info">
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <fa-icon [icon]="building"></fa-icon><strong> Antwerp, Belgium</strong>
                            </li>
                            <li>
                                <fa-icon [icon]="email"></fa-icon><strong><a href="mailto:info@wouterlanduyt.be">
                                        info@wouterlanduyt.be</a></strong>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <fa-icon [icon]="badge"></fa-icon><strong> {{'about.graduation' | translate}}</strong>
                            </li>
                            <li>
                              <!--<fa-icon [icon]="home"></fa-icon><strong> {{'about.myResearch' | translate}} </strong>-->
                              <a class="btn-social-media" href="https://www.linkedin.com/in/wouter-landuyt-89674825/" rel="tooltip"
                                 target="_blank" title="{{'about.followMeOn' | translate}} LinkedIn">
                                <i class="fa fa-linkedin"></i>
                              </a>
                              <a href="https://www.linkedin.com/in/wouter-landuyt-89674825/"><strong> LinkedIn</strong></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br />
                <p>
                    {{'about.researchDescription' | translate}}
                </p>
            </div>
        </div>
    </div>
</section>
