<header>
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark">
        <div class="home p-1 rounded">
            <ul class="navbar-nav">
                <li class="">
                    <a class="nav-link smooth-scroll text-uppercase" href="#">
                        <!-- <i class='bx bx-home'></i>  -->
                        <!-- {{'header.home' | translate}} -->
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="212" height="142" viewBox="0 0 212 142" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M115.212 72.7793C115.235 72.6835 115.234 72.5836 115.209 72.4883L108.853 48.1891C108.784 47.9251 108.545 47.741 108.273 47.741H99.9939C99.7213 47.741 99.483 47.9248 99.4137 48.1885L91.0798 79.8973C91.0512 80.0061 90.9529 80.0819 90.8404 80.0819V80.0819C90.7258 80.0819 90.6262 80.0032 90.5996 79.8918L83.0502 48.2019C82.9858 47.9317 82.7443 47.741 82.4665 47.741H72.9861C72.5874 47.741 72.2996 48.1227 72.4093 48.506L85.3841 93.8514C85.4577 94.1089 85.6931 94.2864 85.9609 94.2864H94.6489C94.9158 94.2864 95.1507 94.1101 95.2251 93.8538L103.89 64.0293C103.92 63.9258 104.014 63.8546 104.122 63.8546V63.8546C104.23 63.8546 104.325 63.9257 104.355 64.0291L111.039 86.9788V86.9788C111.157 87.3817 111.732 87.3676 111.83 86.9593L115.212 72.7793ZM114.428 93.5472C114.338 93.9242 114.624 94.2864 115.012 94.2864H122.017C122.017 94.2864 122.017 94.2867 122.017 94.287V94.287C122.017 94.2873 122.018 94.2876 122.018 94.2876H140.789C141.07 94.2876 141.313 94.0924 141.374 93.8181L143.026 86.4061C143.109 86.0313 142.824 85.6757 142.44 85.6757H126.018C125.619 85.6757 125.332 85.2939 125.441 84.9106L135.858 48.506C135.967 48.1227 135.68 47.741 135.281 47.741H125.829C125.551 47.741 125.309 47.9316 125.245 48.2017L114.428 93.5472Z" fill="white"/>
                            </svg>
                        
                    </a>
                </li>
            </ul>
        </div>
        <button class="navbar-toggler nav mb-1 navbtn">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="container ">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item p-1 rounded">
                        <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#about">
                            <!-- <i class='bx bx-user'></i>  -->
                            {{'header.about' | translate}}
                        </a>
                    </li>
                    <li class="nav-item p-1 rounded">
                        <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#skills">
                            <!-- <i class='bx bx-briefcase-alt'></i> -->
                            {{'header.skills' | translate}}</a>
                    </li>
                    <li class="nav-item p-1 rounded">
                        <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#certification"> 
                            <!-- <i  class='bx bx-file-blank'></i> -->
                            Certifications</a>
                    </li>
                    <li class="nav-item p-1 rounded">
                        <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#projects"> 
                            <!-- <i class='bx bx-book-content'></i> -->
                            Projects</a>
                    </li>
                    <!-- <li class="nav-item p-1 rounded">
                        <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#references">
                            <i class='bx bxs-badge-check'></i> 
                            {{'header.references' | translate}}</a>
                    </li> -->
                    <!-- <li class="nav-item p-1 rounded">
                        <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#contact"> 
                            <i  class='bx bx-envelope'></i>
                            {{'header.contactMe' | translate}}</a>
                    </li> -->
                    <!-- <li class="nav-item p-1 rounded .lang">
                        <mat-radio-group  [(ngModel)]="language" style="margin-top: 5px;"
                            (change)="languageChange($event)" aria-label="Select a language">
                            <mat-radio-button value="en" >
                                English
                            </mat-radio-button>
                            <mat-radio-button value="fr" class="pl-3">
                                Français
                            </mat-radio-button>
                        </mat-radio-group>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</header>
